import AirHealthLogo from "components/AirHealthLogo";

export default function Custom404() {
  return (
    <div className="py-16 px-4 min-h-full sm:py-24 sm:px-6 md:grid md:place-items-center lg:px-8">
      <div className="mx-auto max-w-max">
        <div className=" items-center">
          <AirHealthLogo />
        </div>
        <main className="mt-4 sm:flex">
          <p className="text-4xl font-extrabold text-[#4C9D9B] sm:text-5xl">
            404
          </p>
          <div className="sm:ml-6">
            <div className="sm:pl-6 sm:border-l sm:border-gray-200">
              <h1 className="text-4xl font-extrabold tracking-tight text-[#566670] sm:text-5xl">
                Page not found
              </h1>
              <p className="mt-4 text-base text-gray-500">
                Please check the URL in the address bar and try again.
              </p>
            </div>
            <div className="flex mt-10 space-x-3 sm:pl-6 sm:border-l sm:border-transparent">
              <a
                href="https://air.health"
                className="inline-flex items-center py-2 px-4 text-sm font-medium text-white bg-[#4C9D9B] hover:bg-[#044949] rounded-md border border-transparent focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 shadow-sm"
              >
                Go back home
              </a>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}
